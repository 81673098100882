const END_POINT =
  'https://fra1.digitaloceanspaces.com/kf-public/static/franciacorta/api';
/*
Icone da associare ai markers. La chiave corrisponde alla categoria nei places
*/
export const icons = {
  default: 'https://fra1.digitaloceanspaces.com/kf-public/static/franciacorta/map/assets/v2/marker-default.svg',
  bookmark: 'https://fra1.digitaloceanspaces.com/kf-public/static/franciacorta/map/assets/v2/fc-marker-saved.svg',
  luoghi_da_visitare: 'https://fra1.digitaloceanspaces.com/kf-public/static/franciacorta/map/assets/v2/ldv_marker.svg',
  cantine: 'https://www.barcelo.com/guia-turismo/wp-content/uploads/2018/09/marker-comer-y-beber.png',
  agriturismo: 'https://www.villasantatecla.it/wp-content/uploads/2016/10/map-marker-hotel.png',
  pasticcerie: 'https://static.thenounproject.com/png/872977-200.png',
  wine_bar: 'https://static.thenounproject.com/png/872977-200.png',
  bed_and_breakfast: 'https://static.thenounproject.com/png/872977-200.png',
  campeggio: 'https://static.thenounproject.com/png/872977-200.png',
  hotel: 'https://static.thenounproject.com/png/872977-200.png',
};

export const tagToCatMap = [
  {
    tagname: 'dove-mangiare',
    taglabel: 'Dove Mangiare',
    catmap: ['ristoranti', 'agriturismi'],
  },
];

/**
 * Restituisce i places.
 *
 * @return {Promise} 
 */
export function fetchPlaces () {
  return fetch ('https://franciacorta.wine/wp-json/api/points', {
    credentials: 'include',
  }).then (response => response.json ());
}

/**
 * Restituisce lo style.
 *
 * @return {Promise} 
 */
export function fetchStyle (styleName) {
  return fetch ('https://fc-options.kf-api.workers.dev')
    .then (response => {
      return response.json ();
    })
    .then (data => {
      return data.mapStyles;
    });
}

/**
 * Restituisce l'area franciacorta.
 *
 * @return {Promise} 
 */
export function fetchPaths () {
  return fetch ('https://fc-paths.kf-api.workers.dev').then (response => {
    return response.json ();
  });
}
