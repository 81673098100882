import {useContext} from 'preact/hooks';
import StoreContext from '../../context/store';
import {placeIsBookmarked} from '../../context/selectors';
import {Text} from 'preact-i18n';
import {getLanguage} from '../../utils/utils';

const FC_SidebarElement = ({place}) => {
  const store = useContext (StoreContext);
  const {state, dispatch} = store;
  const {directionPoints, bookmarks} = state;
  const language = getLanguage ();
  const isBookMarked = placeIsBookmarked (bookmarks, place);

  /*
  const _text = directionPoints.size === 0
    ? <FontAwesomeIcon icon={faFlag} />
    : <FontAwesomeIcon icon={faPlus} />;
*/
  return (
    <div
      className="kf-list-card"
      onClick={() =>
        dispatch ({type: 'marker_click', payload: {activeMarker: place}})}
    >
      <div className="card-img-container">
        <div class="kf-list-card__inner">
          <div class="kf-list-card__col1">
            <img
              class="kf-list-card__img"
              width="100"
              height="100"
              src={place.img}
              alt=""
              onClick={() => {
                dispatch ({
                  type: 'marker_click',
                  payload: {activeMarker: place},
                });

                if (window.innerWidth < 800) {
                  dispatch ({
                    type: 'set_sidebar_visible',
                    payload: {sidebarOpen: true},
                  });
                }
              }}
            />
            {isBookMarked
              ? <div><span class="fc-icon icon-salva-green">.</span></div>
              : []}
          </div>
          <div class="kf-list-card__col2">
            <p class="kf-list-card__cat">{place.categoria.name}</p>
            <p
              onClick={() => {
                dispatch ({
                  type: 'marker_click',
                  payload: {activeMarker: place},
                });

                if (window.innerWidth < 800) {
                  dispatch ({
                    type: 'set_sidebar_visible',
                    payload: {sidebarOpen: true},
                  });
                }
              }}
              class={
                isBookMarked
                  ? 'kf-list-card__name'
                  : 'kf-list-card__name title-not-bookmarked'
              }
            >
              {' '}{place.title}{' '}
            </p>
          </div>
        </div>
        {isBookMarked
          ? <div
              class="kf-list-card__action"
              onClick={() => {
                dispatch ({type: 'bookmark_remove', payload: place.slug});
              }}
            >
              <span class="fc-icon icon-close" />
              <Text id="Rimuovi dai preferiti" />
            </div>
          : <div
              class="kf-list-card__action"
              onClick={() => {
                dispatch ({type: 'bookmark_add', payload: place.slug});
              }}
            >
              <span class="fc-icon icon-salva" />
              <Text id="Aggiungi ai preferiti" />
            </div>}
      </div>
    </div>
  );

  /* <div className="card-description">
      <span>Type</span>
      <h4
        onClick={() => {
          dispatch({ type: 'marker_click', payload: { activeMarker: place } });
        }}
      >
        {' '}{place.title}{' '}
      </h4>
      {/* <div class="card-icons">
    <p
      onclick={() =>
        dispatch({ type: 'add_direction_point', payload: place.slug })}
    >
      {!directionPoints.has(place.slug) && <span>{_text}</span>}
    </p>
    
    <BookmarkIcon
      isActive={isBookMarked}
      slug={place.slug}
      dispatch={dispatch}
    />
  </div> */
};

export default FC_SidebarElement;
