import {createContext} from 'preact';
import {getBookmarksFromLocalStorage} from './selectors';

const elMapCont =
  document.getElementById ('kffc-smartmap__container') ||
  document.createElement ('div');

if (!elMapCont.dataset) {
  elMapCont.dataset = {};
}

const getValFromBoolDataset = (datasetVal, defaultVal) => {
  if (typeof datasetVal === 'undefined') {
    return defaultVal;
  }

  return Boolean (parseInt (datasetVal, 10));
};

const getInitialPlaces = config => {
  if (config && config.places) {
    return config.places;
  }

  if (elMapCont.dataset.places) {
    return elMapCont.dataset.places.split (',');
  }

  return null;
};

const getMapCenter = config => {
  if (config && config.mapCenter) {
    return config.mapCenter;
  }

  if (elMapCont.dataset.mapCenter && elMapCont.dataset.mapCenter !== '0') {
    const latlng = elMapCont.dataset.mapCenter.split (',');

    return {
      lat: parseFloat (latlng[0]),
      lng: parseFloat (latlng[1]),
    };
  }

  return null;
};

export function getDefaultState (config) {
  return {
    mapCenter: getMapCenter (config),
    userLocation: {
      lat: null,
      lng: null,
    },
    places: false,
    categories: false,
    tags: false,
    servizi: false,
    mood: false,
    lingue: false,
    activeMarker: null,
    hoveredPlace: null,
    infoWindowIsOpen: false,
    directionPoints: new Set (),
    // prende i preferiti dal LS (se non presenti si inizializza come empty Set)
    bookmarks: getBookmarksFromLocalStorage (),
    bookmarksVisible: false,
    ui_map_zoom: (config && config.zoom) ||
      (elMapCont && parseInt (elMapCont.dataset.zoom, 10)) ||
      12,
    ui_categoria: new Set (),
    ui_tag: new Set (),
    ui_mood: new Set (),
    ui_lingue: new Set (),
    ui_servizi: new Set (),
    ui_option: new Set (),
    ui_show_ui: (config && config.showUI) ||
      getValFromBoolDataset (elMapCont.dataset.showUi, false), //se è false nasconde la sidebar
    ui_show_edge: (config && config.showEdge) ||
      getValFromBoolDataset (elMapCont.dataset.showEdge, false),
    ui_show_fcstreet: (config && config.showFCStreet) ||
      getValFromBoolDataset (elMapCont.dataset.showFcstreet, false),
    ui_map_style_name: elMapCont.dataset.mapStyle || 'schede',
    ui_show_only_bk: false,
    ui_map_style_json: null,
    path_area: null,
    path_street: null,
    //ui_places_from_page: [],
    ui_places_from_page: getInitialPlaces (config),
    ui_places_filter_servizi: null,
    ui_can_filter_places: (config && config.canFilterPlaces) ||
      getValFromBoolDataset (elMapCont.dataset.canFilterPlaces, false),
    ui_places_filter_lingue: null,
    ui_places_filter_mood: null,
    ui_filter_callback: null,
    gmapsAPI: null, // riferimento a google.maps api
    fcMap: null, //riferimento alla mappa franciacorta
    visiblePlaces: 300, //places visibili di default,
    sidebar_open: false,
    kml_layer_url: (config && config.kmlLayer) || elMapCont.dataset.kmlLayer,
    definition: null,
    mobile_filters_active: true,
    mobile_results_active: false,
  };
}

const StoreContext = createContext ();

export default StoreContext;
