import './style';
import App from './components/app';
import {render} from 'preact';

window.FCMAP_SHOW = config => {
  const _containerId = !config || typeof config.containerId === 'undefined'
    ? 'kffc-smartmap__container'
    : config.containerId;

  const _container = document.getElementById (_containerId);

  render (<App config={config} />, _container);
};
