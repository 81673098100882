import {h, options} from 'preact';
import MapContainer from '../gmap';
import {useContext, useEffect} from 'preact/hooks';
import StoreContext from '../../context/store';
import Sidebar from '../sidebar/Sidebar';

const Home = () => {
  const store = useContext (StoreContext);
  const {state, dispatch} = store;
  const {ui_show_ui} = state;

  useEffect (() => {
    dispatch ({type: 'GET_PLACES'});
    dispatch ({type: 'GET_STYLE', payload: state.ui_map_style_name});
    dispatch ({type: 'GET_PATHS'});
  }, []);

  return (
    <div className="kffc-smartmap__wrapper">
      {ui_show_ui && state.places && <Sidebar />}

      <div className="map">
        {state.places && state.ui_map_style_json && <MapContainer />}
      </div>
    </div>
  );
};

export default Home;
