import {fetchPlaces, fetchStyle, fetchPaths} from './data';

let places, styles, paths;

export default function dispatchMiddleware (dispatch) {
  return async action => {
    switch (action.type) {
      case 'GET_PLACES':
        const _now = Math.floor (Date.now () / 1000);
        const _sessPlaces = JSON.parse (sessionStorage.getItem ('FC_PLACES'));
        const _sessPlacesDate = sessionStorage.getItem ('FC_PLACES_DATE') || 0;

        if (_now - _sessPlacesDate < 1200 && Array.isArray (_sessPlaces)) {
          places = [..._sessPlaces];
        } else {
          places = await fetchPlaces ();
          sessionStorage.setItem ('FC_PLACES', JSON.stringify (places));
          sessionStorage.setItem ('FC_PLACES_DATE', _now);
        }

        dispatch ({
          type: action.type,
          payload: {
            places: places.points,
            categories: places.categorie,
            tags: places.tags,
            servizi: places.servizi,
            mood: places.mood,
            lingue: places.lingue,
          },
        });

        break;

      case 'GET_STYLE':
        styles = await fetchStyle (action.payload);

        dispatch ({type: action.type, payload: styles});

        break;

      case 'GET_PATHS':
        paths = await fetchPaths ();

        dispatch ({type: action.type, payload: paths});

        break;

      default:
        return dispatch (action);
    }
  };
}
