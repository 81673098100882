import {getLanguage, getFilterValItFromLocalized} from '../utils/utils';

export default function rootreducer (draft, action) {
  const _lang = getLanguage ();

  switch (action.type) {
    case 'set_places':
      draft.ui_categoria.add (action.payload.category);

      break;

    case 'toggle_type':
      let _ui_type;

      if (action.payload.type === 'option') {
        _ui_type = `ui_${action.payload.value}`;
        draft[_ui_type] = !draft[_ui_type];
      } else {
        _ui_type = `ui_${action.payload.type}`;

        if (draft[_ui_type].has (action.payload.value)) {
          draft[_ui_type].delete (action.payload.value);
        } else {
          draft[_ui_type].add (action.payload.value);
        }
      }

      if (typeof action.payload.filterCallback === 'function') {
        draft.ui_filter_callback = action.payload.filterCallback;
      }

      break;

    case 'toggle_tag':
      if (draft.ui_tag.has (action.payload)) {
        draft.ui_tag.delete (action.payload);
      } else {
        draft.ui_tag.add (action.payload);
      }

      break;

    case 'toggle_mood':
      if (draft.ui_mood.has (action.payload)) {
        draft.ui_mood.delete (action.payload);
      } else {
        draft.ui_mood.add (action.payload);
      }

    case 'marker_click':
      draft.activeMarker = action.payload.activeMarker;
      draft.infoWindowIsOpen = true;
      break;

    case 'place_hover':
      draft.hoveredPlace = action.payload;

    case 'map_click':
      draft.infoWindowIsOpen = false;
      draft.activeMarker = null;
      break;

    case 'google_map_loaded':
      draft.gmapsAPI = action.payload.maps;
      draft.fcMap = action.payload.map;

      const bounds = action.payload.maps.LatLngBounds ();
      //action.payload.map.fitBounds(bounds);

      break;

    case 'google_map_unmount':
      draft.fcMap = null;

      break;

    case 'infowindow_close':
      draft.infoWindowIsOpen = false;

      break;

    case 'bookmark_add':
      draft.bookmarks.add (action.payload);

      // TODO: portare fuori questa funzione in modo da poterla riutilizzare
      localStorage.setItem (
        'FC_Bookmarks',
        JSON.stringify (Array.from (draft.bookmarks))
      );

      break;

    case 'bookmark_remove':
      draft.bookmarks.delete (action.payload);

      // TODO: portare fuori questa funzione in modo da poterla riutilizzare
      localStorage.setItem (
        'FC_Bookmarks',
        JSON.stringify (Array.from (draft.bookmarks))
      );

      break;
    case 'add_direction_point':
      draft.directionPoints.add (action.payload);

      break;

    case 'set_user_location':
      draft.userLocation.lat = action.payload.usrLat;
      draft.userLocation.lng = action.payload.usrLng;
      break;

    case 'set_visible_places':
      draft.visiblePlaces += 1;
      break;

    case 'set_bookmarks_visible':
      draft.bookmarksVisible = true;
      break;

    case 'GET_PLACES':
      draft.categories = action.payload.categories;
      draft.tags = action.payload.tags;
      draft.servizi = action.payload.servizi;
      draft.mood = action.payload.mood;
      draft.lingue = action.payload.lingue;

      const _placesMapper = place => {
        try {
          place.categoria = {
            id: place.categoria[0],
            name: action.payload.categories[place.categoria[0]].name[_lang],
            slug: action.payload.categories[place.categoria[0]].slug['it'],
          };

          place.tag =
            place.tag &&
            place.tag.map (tag => action.payload.tags[tag].slug['it']);

          return place;
        } catch (e) {
          console.error ('______>', e, place);
          return place;
        }
      };

      const _places = action.payload.places
        .map (_placesMapper)
        .sort ((placeA, placeB) => {
          return placeA.categoria.name === 'Cantine' &&
            placeB.categoria.name !== 'Cantine'
            ? 1
            : -1;
        });

      draft.places = _places;

      break;

    case 'GET_STYLE':
      draft.ui_map_style_json = action.payload;
      break;

    case 'GET_PATHS':
      draft.path_area = action.payload.area_fc;
      draft.path_street = action.payload.street_fc;
      break;

    case 'SET_UI_FILTER':
      console.log ('SET_UI_FILTER', action.payload);

      if (Array.isArray (action.payload.filters)) {
        action.payload.filters.forEach (filter => {
          if (!Array.isArray (filter.filterValue)) return false;

          let _filterValue = [...filter.filterValue].map (value =>
            getFilterValItFromLocalized (
              draft,
              action.payload.filters,
              filter.filterName,
              value
            )
          );
          draft[filter.filterName] = new Set (_filterValue);
        });
      }

      if (typeof action.payload.filterCallback === 'function') {
        draft.ui_filter_callback = action.payload.filterCallback;
      }

      break;

    case 'update_page_places':
      draft.ui_places_from_page = action.payload;

      break;

    case 'set_sidebar_visible':
      if (action.payload.sidebarOpen !== true) {
        draft.sidebarOpen = true;
      } else {
        draft.sidebarOpen = false;
      }
      break;

    case 'set_mobile_filters_active':
      draft.mobile_filters_active = true;
      draft.mobile_results_active = false;
      break;

    case 'set_mobile_results_active':
      draft.mobile_filters_active = false;
      draft.mobile_results_active = true;
      break;

    default:
      break;
  }
}
