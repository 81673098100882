import { Marker } from '@react-google-maps/api';
import { icons } from '../../context/data';
import { useContext } from 'preact/hooks';
import StoreContext from '../../context/store';
import { getBookmarksFromLocalStorage } from '../../context/selectors';

const getIconURL = (place, isBookmark) => {
  let iconURL;
  const isTagPlaces = Array.isArray(place.tag) &&
    place.tag.includes('luoghi-da-visitare')
  iconURL = isBookmark ? icons.bookmark : icons.default;
  try {
    iconURL = isTagPlaces && isBookmark ? icons.bookmark :
      isTagPlaces
        ? icons.luoghi_da_visitare
        : iconURL;
  } catch (e) {
    console.log(place);
    // iconURL = iconURL;
  }
  return iconURL;
};

const FC_Marker = ({ place, clusterer }) => {
  const store = useContext(StoreContext);
  const { state, dispatch } = store;
  const { lat, lng } = place;

  const isHovered = place.slug === state.hoveredPlace;
  const iconSize = isHovered ? 53 : 53;
  const isBookm = getBookmarksFromLocalStorage().has(place.slug);
  const iconURL = getIconURL(place, isBookm);

  return (
    <Marker
      icon={{
        url: iconURL,
        scaledSize: state.gmapsAPI &&
          new state.gmapsAPI.Size(iconSize, iconSize),
      }}
      position={{ lat, lng }}
      clusterer={clusterer}
      onClick={() => {
        dispatch({ type: 'marker_click', payload: { activeMarker: place } });
      }}
    />
  );
};

export default FC_Marker;
