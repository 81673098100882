const memoize = require ('lodash/memoize');

const DEFAULT_LANGUAGE = 'it';

export const getLanguage = memoize (() => {
  const parts = window.location.pathname.split ('/');

  if (parts.length < 2 || parts[1] === '') {
    return DEFAULT_LANGUAGE;
  }

  return parts[1];
});

export function eqById (pl1, pl2) {
  return pl1.id === pl2.id;
}

export function getPlaceUrl (state, place) {
  if (!place) {
    return '';
  }

  const language = getLanguage ();
  const host = window.location.host;
  const categoriaObj = state.categories[place.categoria.id].slug;
  const categoriaLoc = categoriaObj[language];
  const slug = place.slug;
  const permalink = `//${host}/${language}/${categoriaLoc}/${slug}`;

  return permalink;
}

export function getFilterValItFromLocalized (
  state,
  filters,
  filterName,
  filterValue
) {
  const lang = getLanguage ();
  const _filterName = filterName.split ('_').pop ();
  const langArrayOrig = Object.keys (state[_filterName][lang]);
  const langArrayIt = Object.keys (state[_filterName].it);
  const valueIndex = langArrayOrig.indexOf (filterValue);
  const valIt = langArrayIt[valueIndex];

  return valIt;
}
