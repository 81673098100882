import {useContext} from 'preact/hooks';
import StoreContext from '../../context/store';
import {getTypeIsSelected} from '../../context/selectors';
import {Text} from 'preact-i18n';

const FC_ToggleButton = ({type, value, label}) => {
  const store = useContext (StoreContext);
  const {state, dispatch} = store;

  const getIsSelected = () => {
    return getTypeIsSelected (state, type, value);
  };

  return (
    <div
      className="toggle-button map-toggle-button"
      style={{marginBottom: '15px'}}
      onClick={() => {
        dispatch ({
          type: 'toggle_type',
          payload: {type, value},
        });
      }}
    >
      <input type="checkbox" checked={getIsSelected ()} />
      <label>
        <Text id={label}>{label}</Text>
      </label>
    </div>
  );
};

export default FC_ToggleButton;
