import {h} from 'preact';
import {useReducer} from 'preact/hooks';
import RootReducer from '../context/reducer';
import StoreContext, {getDefaultState} from '../context/store';
import Home from './home';
import produce from 'immer';
import dispatchMiddleware from '../context/dispatchMiddleware';
import {getBookmarksAsPlaces} from '../context/selectors';
import {enableMapSet} from 'immer';
import {IntlProvider} from 'preact-i18n';
import translations from '../translations/translations.json';
import {getLanguage} from '../utils/utils';

enableMapSet ();

const App = ({config}) => {
  const [state, _dispatch] = useReducer (
    produce (RootReducer),
    getDefaultState (config)
  );
  const store = {state, dispatch: dispatchMiddleware (_dispatch)};

  // INTERFACE
  window.FCMAP_DISPATCH = _dispatch;
  window.FCMAP_PLACES = state.places;

  window.FCMAP_GET_BOOKMARKS = callback => {
    let bkTimer;

    const fnFilter = () => {
      const _sessPlaces = JSON.parse (sessionStorage.getItem ('FC_PLACES'))
        .points;
      if (Array.isArray (_sessPlaces)) {
        callback (getBookmarksAsPlaces ([..._sessPlaces]));
        clearInterval (bkTimer);
      }
    };

    bkTimer = setInterval (fnFilter, 110);
  };

  const definition = translations[getLanguage ()];

  return (
    <IntlProvider definition={definition}>
      <StoreContext.Provider value={store}>
        <Home />
      </StoreContext.Provider>
    </IntlProvider>
  );
};

export default App;
