const intersection = require ('lodash/intersection');
const intersectionWith = require ('lodash/intersectionWith');
const memoize = require ('lodash/memoize');
const uniqWith = require ('lodash/uniqWith');
const {eqById} = require ('../utils/utils');
import {getLanguage} from '../utils/utils';

export const getPlacesByType = memoize (
  (filterValues, filterType, logicType, places) => {
    if (!filterValues || filterValues.length === 0) {
      return [];
    }

    return places.filter (place => {
      const _placeTypeVal = filterType.includes ('.')
        ? [place[filterType.split ('.')[0]][filterType.split ('.')[1]]]
        : place[filterType];

      if (logicType === 'OR') {
        const _int = intersection (filterValues, _placeTypeVal);
        return _int.length > 0;
      } else {
        return (
          intersection (filterValues, _placeTypeVal).length ===
          filterValues.length
        );
      }
    });
  },
  (vals, type) => `${type}_${JSON.stringify (vals)}`
);

/**
 * True se la voce passata è attiva in quel determinato tipo
 *
 * @param {*} state
 * @param {*} type
 * @param {*} value
 * @return {*} 
 */
export const getTypeIsSelected = (state, type, value) => {
  if (type === 'option') {
    return state[`ui_${value}`];
  }

  return state[`ui_${type}`].has (value);
};

/**
 * True se il tag passato è presente tra quelli attivi
 *
 * @param {*} state
 * @param {*} tag
 * @return {*} 
 */
export const getTagIsSelected = (state, tag) => {
  return state.ui_tag.has (tag);
};

/**
 * Restituisce i places attivi in base ad un array di valori 
 * relativi a un tipo di filtro
 * Utilizza reselect per gestire e memorizzare la selezione
 *
 */
export const selPlacesByType = (places, state, type, logic) => {
  const _type = type.includes ('.') ? type.split ('.')[0] : type;
  const uiTypeArray = Array.from (state[`ui_${_type}`]);

  return getPlacesByType (uiTypeArray, type, logic, places);
};

export const selectPlaceByID = (places, id) =>
  places.filter (place => place.id === id)[0];

/**
 * True se il place è presente nei bookmarks
 *
 * @param {*} bookmarks
 * @param {*} place
 * @return {*} 
 */
export const placeIsBookmarked = (bookmarks, place) => {
  if (!place) return false;
  if (typeof place === 'undefined') return false;
  const {slug} = place;
  return bookmarks.has (slug);
};

const getBasePlaces = state => {
  if (
    !Array.isArray (state.ui_places_from_page) ||
    state.ui_places_from_page.length === 0 ||
    state.ui_places_from_page[0] === 'all'
  ) {
    return state.places;
  }

  if (state.ui_places_from_page[0] === 'bookmarks') {
    return getBookmarksAsPlaces (state.places);
  }

  return state.ui_places_from_page.map (id =>
    selectPlaceByID (state.places, parseInt (id, 10))
  );
};

/**
 * Restituisce l'array dei markers da visualizzare
 * Utlizza varie fonti (categorie selezionate, preferiti, punti del percorso ecc) e li
 * concatena
 * Il passaggio degli argomenti a cascata è un pò ridondante ma in proepsttiva
 * permette di gestire meglio altri parametri come filtri/esclusioni oltre che di 
 * poter scrivere test più facilmente
 * 
 * @param {*} state
 */
export const getPlacesToDisplay = memoize (
  state => {
    let basePlaces = getBasePlaces (state);

    if (state.ui_categoria.size > 0 || state.ui_tag.size > 0) {
      const placesByCat = selPlacesByType (
        basePlaces,
        state,
        'categoria.slug',
        'OR'
      );
      const placesByTag = selPlacesByType (basePlaces, state, 'tag', 'OR');
      basePlaces = uniqWith ([...placesByCat, ...placesByTag], eqById);
    }

    if (
      state.ui_mood.size > 0 ||
      state.ui_lingue.size > 0 ||
      state.ui_servizi.size > 0
    ) {
      const placesByMood = state.ui_mood.size > 0
        ? selPlacesByType (basePlaces, state, 'mood', 'AND')
        : basePlaces;
      const placesByLingue = state.ui_lingue.size > 0
        ? selPlacesByType (basePlaces, state, 'lingue', 'AND')
        : basePlaces;
      const placesByServizi = state.ui_servizi.size > 0
        ? selPlacesByType (basePlaces, state, 'servizi', 'AND')
        : basePlaces;

      basePlaces = intersectionWith (
        basePlaces,
        placesByMood,
        placesByLingue,
        placesByServizi,
        eqById
      );
    }

    if (true === state.ui_show_only_bk) {
      return getBookmarksAsPlaces (basePlaces);
    }

    return basePlaces;
  },
  state => {
    return [
      ...state.ui_categoria,
      ...state.ui_tag,
      ...state.ui_mood,
      ...state.ui_lingue,
      ...state.ui_servizi,
      ...state.ui_option,
      state.ui_show_only_bk,
    ].join ('_');
  }
);

export const getLatLngFromSlug = (state, slug) => {
  const place = state.places.filter (place => place.slug === slug);

  if (place.length === 0) {
    throw new Error ('No place found!');
  }

  return new state.gmapsAPI.LatLng (place[0].lat, place[0].lng);
};

export const getDirectionsEdges = state => {
  if (state.directionPoints.size < 2) {
    throw new Error ('Non ci sono ancora due punti impostati');
  }

  const pointsAsArray = Array.from (state.directionPoints);
  const startSlug = pointsAsArray[0];
  const endSlug = pointsAsArray[pointsAsArray.length - 1];

  const startLatLng = getLatLngFromSlug (state, startSlug);
  const endLatLng = getLatLngFromSlug (state, endSlug);

  return [startLatLng, endLatLng];
};

export const getDirectionsWaypoints = state => {
  if (state.directionPoints.size < 3) {
    return [];
  }

  const pointsAsArray = Array.from (state.directionPoints);
  pointsAsArray.shift ();
  pointsAsArray.pop ();

  const points = pointsAsArray.map (slug => ({
    location: getLatLngFromSlug (state, slug),
    stopover: true,
  }));

  return points;
};

/**
 * Recupera i preferiti dal local storage
 * Se non sono presenti o il formato non viene riconosciuto torna un Set vuoto
 *
 * @return {Set} 
 */
export const getBookmarksFromLocalStorage = () => {
  try {
    const rawBooks = localStorage.getItem ('FC_Bookmarks');

    if (!rawBooks) return new Set ();

    const jsonBooks = JSON.parse (rawBooks);

    if (Array.isArray (jsonBooks)) {
      return new Set (jsonBooks);
    }

    throw new Error ('Impossibile recuperare i bookmarks');
  } catch (e) {
    return new Set ();
  }
};

export const getBookmarksAsPlaces = places => {
  const bookmarksSlugs = getBookmarksFromLocalStorage ();

  return [...places].filter (place => bookmarksSlugs.has (place.slug));
};

export const getLocalizedFieldById = (state, type, field, id) => {
  const _lang = getLanguage ();
  const _type = state[type];
  const _byid = _type[id] || null;
  const _field = _byid && (_byid[field] || null);
  const _bylang = _field && (_field[_lang] || null);

  return _bylang;
};
