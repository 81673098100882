import React from 'react';
import FC_ToggleButton from '../toggle-button';
import FC_SidebarElement from '../sidebar-element';
import { useContext } from 'preact/hooks';
import StoreContext from '../../context/store';
import { getLatLngFromSlug, getPlacesToDisplay } from '../../context/selectors';
import { Text } from 'preact-i18n';

const Sidebar = () => {
  const store = useContext(StoreContext);
  const { state, dispatch } = store;
  const { visiblePlaces, userLocation, bookmarks, bookmarksVisible, sidebarOpen, mobile_filters_active, mobile_results_active } = state;
  const placesToDisplay = getPlacesToDisplay(state);
  let slicedPlaces = placesToDisplay.slice(0, 300)

  return (
    <>
      <div className={sidebarOpen ? 'sidebar sidebar-open' : 'sidebar'}>
        <div className="sidebar-row">
          <div className={mobile_filters_active ? 'sidebar-left active' : 'sidebar-left'}>
            <div className="sidebar-filters">
              <h2><Text id="Opzioni mappa"></Text></h2>
              <div className='filter-box'>
                <FC_ToggleButton type="option" value="show_fcstreet" label="Mostra strada" />
                <FC_ToggleButton type="option" value="show_only_bk" label="Mostra preferiti" />
              </div>
            </div>
            <div className="sidebar-filters">
              <h2><Text id="Filtra per"></Text></h2>
              <div className='filter-box'>
                <h3><Text id="Tipologie"></Text></h3>
                <FC_ToggleButton type="tag" value="mangiare" label="Dove mangiare" />
                <FC_ToggleButton type="tag" value="dormire" label="Dove dormire" />
                <FC_ToggleButton type="tag" value="acquistare" label="Dove acquistare" />
                <FC_ToggleButton type="tag" value="luoghi-da-visitare" label="Luoghi da visitare" />
              </div>
              <div className='filter-box'>
                <h3><Text id="Categorie"></Text></h3>
                <FC_ToggleButton type="categoria" value="cantine" label="Cantine" />
                <FC_ToggleButton type="categoria" value="ristoranti" label="Ristoranti" />
                <FC_ToggleButton type="categoria" value="agriturismi" label="Agriturismi" />
                <FC_ToggleButton type="categoria" value="hotel" label="Hotel" />
                <FC_ToggleButton type="categoria" value="beb-camping" label="BB e Campeggi" />
                <FC_ToggleButton type="categoria" value="bar-enoteche" label="Enoteche e distillerie" />
                <FC_ToggleButton type="categoria" value="prodotti-tipici" label="Prodotti tipici" />
                <FC_ToggleButton type="categoria" value="servizi" label="Servizi" />
                <FC_ToggleButton type="categoria" value="sport" label="Sport" />
                <FC_ToggleButton type="categoria" value="associazioni-consorzi" label="Associazioni ed Enti" />
              </div>
              <div className='filter-box'>
                <h3><Text id="Luoghi da visitare"></Text></h3>
                <FC_ToggleButton type="categoria" value="abbazie-chiese" label="Abbazie e Chiese" />
                <FC_ToggleButton type="categoria" value="location" label="Dimore storiche e Location" />
                <FC_ToggleButton type="categoria" value="musei" label="Musei" />
              </div>
              <div className='filter-box'>
                <h3><Text id="Mood"></Text></h3>
                <FC_ToggleButton type="mood" value="per-famiglie" label="Per famiglie" />
                <FC_ToggleButton type="mood" value="per-matrimoni" label="Per matrimoni" />
                <FC_ToggleButton type="mood" value="romantica" label="Romantica" />
                <FC_ToggleButton type="mood" value="artistica" label="Artistica" />
                <FC_ToggleButton type="mood" value="per-eventi" label="Per eventi" />
                <FC_ToggleButton type="mood" value="rustica" label="Rustica" />
                <FC_ToggleButton type="mood" value="sportiva" label="Sportiva" />
              </div>
              <div className='filter-box'>
                <h3><Text id="Lingue"></Text></h3>
                <FC_ToggleButton type="lingue" value="en" label="Inglese" />
                <FC_ToggleButton type="lingue" value="fr" label="Francese" />
                <FC_ToggleButton type="lingue" value="de" label="Tedesco" />
                <FC_ToggleButton type="lingue" value="es" label="Spagnolo" />
              </div>
              <div className='filter-box'>
                <h3><Text id="Servizi"></Text></h3>
                <FC_ToggleButton type="servizi" value="accesso-disabili" label="Accesso disabili" />
                <FC_ToggleButton type="servizi" value="consentito-ingresso-agli-animali" label="Animali ammessi" />
                <FC_ToggleButton type="servizi" value="spa" label="SPA" />
                <FC_ToggleButton type="servizi" value="biciclette-a-disposizione-degli-ospiti" label="Biciclette ospiti" />
                <FC_ToggleButton type="servizi" value="sala-meeting-convegni" label="Sala meeting" />
                <FC_ToggleButton type="servizi" value="wine-shop-interno" label="Wine shop" />
                <FC_ToggleButton type="servizi" value="visite-e-degustazioni-in-loco" label="Degustazioni" />
                <FC_ToggleButton type="servizi" value="posti-ristorazione-allaperto" label="Ristorazione all'aperto" />
                <FC_ToggleButton type="servizi" value="parcheggio" label="Parcheggio" />
                <FC_ToggleButton type="servizi" value="piscina" label="Piscina" />
              </div>

            </div>

          </div>

          {/* <div className="commands-right">
            <span>
              <img
                src={Compass}
                alt="compass"
                onClick={() => {
                  navigator.geolocation.getCurrentPosition(position => {
                    dispatch({
                      type: 'set_user_location',
                      payload: { usrLat: position.coords.latitude, usrLng: position.coords.longitude },
                    });
                  }, () => null);
                }}
              />
            </span>
            <span><img src={Heart} alt="heart" onClick={() => dispatch({ type: 'set_bookmarks_visible' })} /></span>
          </div> */}

          <div className={mobile_results_active ? 'sidebar-right active' : 'sidebar-right'}>
            <div className="sidebar-results">
              <h2><Text id="Risultati:"></Text> ({slicedPlaces.length})</h2>
              <div className="card-container">
                {
                  !bookmarksVisible ?
                    slicedPlaces.length > 0 &&
                    <>
                      {slicedPlaces.map((place, index) => (
                        place && <FC_SidebarElement place={place} key={index} />
                      ))}
                    </>
                    :
                    bookmarks.length > 0 &&
                    <>
                      {bookmarks.map((place, index) => (
                        place && <FC_SidebarElement place={getLatLngFromSlug(state, place.slug)} key={index} />
                      ))}
                    </>
                }
              </div>
            </div>
            {/* <div class="show-more-btn">
            <button class="btn" onClick={() => dispatch({ type: 'set_visible_places' })}>Carica<br />altro</button>
          </div> */}
          </div>
        </div>
        <div className='sidebar-mobile-pagination'>
          <div className='sidebar-mobile-pagination-row'>
            <div className={mobile_filters_active ? 'sidebar-mobile-pagination-page active' : 'sidebar-mobile-pagination-page'}
              onClick={() => dispatch({ type: 'set_mobile_filters_active', payload: { mobile_filters_active } })}><Text id="Filtri"></Text></div>
            <div className={mobile_results_active ? 'sidebar-mobile-pagination-page active' : 'sidebar-mobile-pagination-page'}
              onClick={() => dispatch({ type: 'set_mobile_results_active', payload: { mobile_results_active } })}><Text id="Risultati"></Text></div>
          </div>
        </div>
      </div>
      <div className={sidebarOpen ? 'sidebar-toggle sidebar-toggle-open' : 'sidebar-toggle'}
        onClick={() => dispatch({ type: 'set_sidebar_visible', payload: { sidebarOpen } })}>
        {
          sidebarOpen ?
            <div className='map-toggle-container'>
              <img src="https://fra1.digitaloceanspaces.com/kf-public/static/franciacorta/map/assets/v2/close-icon.svg" alt="Chiudi i filtri" />
              <span><Text id="Chiudi filtri"></Text></span>
            </div>
            :
            <div className='map-toggle-container'>
              <img src="https://fra1.digitaloceanspaces.com/kf-public/static/franciacorta/map/assets/v2/filters-icon.svg" alt="Mostra i filtri" />
              <span><Text id="Mostra i filtri"></Text></span>
            </div>
        }
      </div>
    </>
  );
};

export default Sidebar;
