const BookmarkIcon = ({ isActive, slug, dispatch }) => (
  <>
    {!isActive &&
      <span>
        <img src="https://fra1.digitaloceanspaces.com/kf-public/static/franciacorta/map/assets/v2/preferiti-icon.svg" onclick={() =>
          dispatch({ type: 'bookmark_add', payload: slug })} />
      </span>
    }

    {isActive &&
      <span>
        <img src="https://fra1.digitaloceanspaces.com/kf-public/static/franciacorta/map/assets/v2/preferiti-saved.svg" onclick={() =>
          dispatch({ type: 'bookmark_remove', payload: slug })} />
      </span>
    }
  </>
)

export default BookmarkIcon;