import { InfoWindow } from '@react-google-maps/api';
import { useContext } from 'preact/hooks';
import { placeIsBookmarked } from '../../context/selectors';
import StoreContext from '../../context/store';
import BookmarkIcon from '../bookmark-icon';
import { getPlaceUrl } from '../../utils/utils';

const FC_InfoWindow = ({ place }) => {
  const store = useContext(StoreContext);
  const { state, dispatch } = store;
  const { bookmarks } = state;
  const isBookMarked = placeIsBookmarked(bookmarks, place);
  const go = () => (window.location.href = getPlaceUrl(state, place));


  if (!place) {
    return (
      <InfoWindow position={{ lat: 45.6212752, lng: 10.0355917 }}>
        <span />
      </InfoWindow>
    );
  }

  return (
    <InfoWindow
      onCloseClick={() => dispatch({ type: 'infowindow_close' })}
      position={{ lat: place.lat, lng: place.lng }}
    >
      <div className="fc-infowindow">
        <div onclick={go} className="fc-infowindow-img">
          <img src={place.img} alt={place.title} />
        </div>
        <div className="fc-infowindow-text">
          <div className="fc-infowindow-category">
            <p>{place.categoria.name}</p>
            <BookmarkIcon
              isActive={isBookMarked}
              slug={place.slug}
              dispatch={dispatch}
            />
          </div>
          <div className="fc-infowindow-title">
            <h2
              className={isBookMarked ? 'bookmarked' : ''}
              onclick={go}
              style={{ cursor: 'pointer' }}
            >
              {place.title}
            </h2>
          </div>
          <div
            className="fc-infowindow-link"
            onclick={go}
            style={{ cursor: 'pointer' }}
          >
            <span className={isBookMarked ? 'bookmarked' : ''}>
              <svg
                width="30"
                height="6"
                viewBox="0 0 30 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.1453 3L24.1453 0.113247L24.1453 5.88675L29.1453 3ZM0.960938 3.5L24.6453 3.5L24.6453 2.5L0.960937 2.5L0.960938 3.5Z"
                  fill="#87734D"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

    </InfoWindow>
  );
};

export default FC_InfoWindow;
